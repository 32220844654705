<template>
  <div class="p-d-flex p-ai-center p-jc-center">
    <div class="p-mb-1" style="width: 80vw">
      <div class="p-grid p-fluid dashboard">
        <div class="p-col-12 p-lg-4">
          <div class="card summary">
            <span class="title">ผู้สูงอายุในระบบ</span>
            <span class="detail">จำนวนผู้สูงอายุทั้งหมด</span>
            <span class="count visitors">{{ statistics.elder }} คน</span>
          </div>
        </div>
        <div class="p-col-12 p-lg-4">
          <div class="card summary">
            <span class="title">อาชีพในระบบ</span>
            <span class="detail">จำนวนอาชีพในทั้งหมด</span>
            <span class="count purchases">{{ statistics.career }} อาชีพ</span>
          </div>
        </div>
        <div class="p-col-12 p-lg-4">
          <div class="card summary">
            <span class="title">หน่วยงานท้องถิ่น</span>
            <span class="detail">จำนวนหน่วยงานท้องถิ่น</span>
            <span class="count revenue">{{ statistics.area }} พื้นที่</span>
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-6 p-lg-3" />
          <div class="p-col-12 p-md-6 p-lg-4">
            <div class="p-d-flex p-jc-center">
              <router-link :to="{ path: 'login' }">
                <Button
                  class="p-button-primary p-button-lg"
                  label="ลงชื่อเข้าใช้งานระบบ"
                  icon="pi pi-sign-in"
                />
              </router-link>
            </div>
          </div>
          <div class="p-col-12 p-md-6 p-lg-5">
            <div class="p-d-flex p-jc-end">
              <router-link :to="{ path: '/videos/tutorial' }">
                <Button
                  class="p-button-success p-button-lg p-mr-2 p-mb-2"
                  label="สอนการใช้งานระบบ"
                  icon="pi pi-video"
                />
              </router-link>
              <router-link :to="{ path: '/suggest' }">
                <Button
                  class="p-button-success p-button-lg"
                  label="ข้อเสนอแนะ"
                  icon="pi pi-comments"
                />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'welcome',
  layout: 'Public',
  created() {
    this.$store.dispatch('statistics/fecthStatisticsState')
  },
  computed: {
    statistics() {
      return this.$store.getters['statistics/toFormat']
    }
  }
}
</script>